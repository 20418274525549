import React, { useState, useRef } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Divider, Input, message, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { isEmpty } from 'lodash';
import { string, number, func, shape, arrayOf } from 'prop-types';

import { PHONE_NUMBER_INPUT_PREFIX } from 'Src/adminFormsX/constants';
import { INPUT_TYPES } from 'Src/common/constants';
import { getFormEntryRecord } from 'Src/common/utilities/form_utils';
import CustomFormItem from 'Src/common/components/customFormItem';

import './style.scss';

const FormItem = Form.Item;

function GuestFormCard({ form, record, index, fields, title, affiliations, validateGuestsEmail, eventData }) {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
  const formEntries = (record.form_entry && record.form_entry.field_entries) || [];
  const [richTextValuesObj, setRichTextValuesObj] = useState(null);

  const totalRichFieldsRef = useRef(0);

  const copyPrimaryCustomFields = () => {
    if (index > 0) {
      const values = form.getFieldsValue();
      const updateFields = {};
      const richTextObj = {};

      fields.forEach((field) => {
        const primaryFieldKey = `${field.key}@_@0`;
        const currentFieldKey = `${field.key}@_@${index}`;

        if (!isEmpty(values[primaryFieldKey])) {
          updateFields[currentFieldKey] = values[primaryFieldKey];

          if (!isEmpty(field.condition_values) && field.condition_field) {
            const conditionFieldKey = `${field.condition_field}@_@${0}`;
            const conditionFieldValue = getFieldValue(conditionFieldKey);
            // creating the field as it's conditional and setFieldsValue does not create one
            if (field.condition_values.includes(conditionFieldValue)) {
              form.getFieldDecorator(`${currentFieldKey}`, {
                initialValue: values[primaryFieldKey],
              });
            }
          }

          if (field.type === INPUT_TYPES.GOOGLE_LOCATION) {
            const primaryFKey = `google_place_${field.key}@_@0`;
            const currentFKey = `google_place_${field.key}@_@${index}`;
            updateFields[currentFKey] = values[primaryFKey];
          }

          if (field.type === INPUT_TYPES.PHONE_NUMBER) {
            const primaryFKey = `${PHONE_NUMBER_INPUT_PREFIX}${field.key}@_@0`;
            const currentFKey = `${PHONE_NUMBER_INPUT_PREFIX}${field.key}@_@${index}`;
            updateFields[currentFKey] = values[primaryFKey];
          }

          if (field.type === 'rich_text') {
            richTextObj[primaryFieldKey] = values[primaryFieldKey];
            totalRichFieldsRef.current = fields.filter((fieldItem) => fieldItem.type === INPUT_TYPES.RICH_TEXT).length;
          }
        }
      });

      if (Object.keys(updateFields).length > 0) {
        setFieldsValue(updateFields);
        if (!isEmpty(richTextObj)) {
          setRichTextValuesObj(richTextObj);
        }
        message.success('Primary guest information copied');
      } else {
        message.info('No information available to copy');
      }
    }
  };

  return (
    <Card title={title} className="guest-form-card regular-form">
      <p className="required-field-text">
        <span className="arc-color-error">*</span> Indicates a required field
      </p>
      <FormItem label="First Name">
        {getFieldDecorator(`first_name@_@${index}`, {
          initialValue: record.first_name,
          rules: [
            {
              required: true,
              message: 'Please enter first name',
            },
          ],
        })(<Input />)}
      </FormItem>
      <FormItem label="Last Name">
        {getFieldDecorator(`last_name@_@${index}`, {
          initialValue: record.last_name,
        })(<Input />)}
      </FormItem>
      <FormItem label="Email">
        {getFieldDecorator(`email@_@${index}`, {
          initialValue: record.email,
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              validator: validateGuestsEmail,
            },
          ],
          validateTrigger: 'onBlur',
        })(<Input disabled={index === 0} />)}
      </FormItem>
      <If condition={eventData.should_collect_affiliation}>
        <FormItem label="Affiliations">
          {getFieldDecorator(`affiliation@_@${index}`, {
            initialValue: record.affiliations?.map((affiliation) => affiliation.id),
          })(
            <Select
              className="guest-affiliation-select"
              placeholder="Alumni"
              mode="multiple"
              allowClear
              aria-expanded="true"
              style={{ width: '100%' }}
              direction="vertical"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {affiliations.map((affiliation) => (
                <Select.Option value={affiliation.id} key={affiliation.id}>
                  {affiliation.name}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem>
      </If>
      <If condition={!isEmpty(fields)}>
        <Divider />
        <If condition={index > 0}>
          <Button className="arc-btn-light arc-support copy-btn mb16" onClick={copyPrimaryCustomFields}>
            <FontAwesomeIcon icon={faCopy} className="mr8" />
            Copy primary guest information
          </Button>
        </If>
      </If>
      {fields.map((item) => {
        const field = { ...item };
        field.key = `${field.key}@_@${index}`;
        field.condition_field = field.condition_field && `${field.condition_field}@_@${index}`;
        return (
          <CustomFormItem
            form={form}
            formInput={field}
            formRecord={getFormEntryRecord(formEntries, field.id, field.type, field.key)}
            key={field.id}
            metadata={{
              source_type: 'customform',
              source_id: eventData && eventData.guest_form && eventData.guest_form.id,
              access_level: 'protected',
            }}
            totalRichFieldsRef={totalRichFieldsRef}
            richTextValuesObj={field.type === 'rich_text' ? richTextValuesObj : null}
          />
        );
      })}
    </Card>
  );
}

GuestFormCard.propTypes = {
  form: shape(),
  index: number.isRequired,
  title: string.isRequired,
  fields: arrayOf(shape()),
  affiliations: arrayOf(shape()),
  validateGuestsEmail: func.isRequired,
  eventData: shape(),
};

GuestFormCard.defaultProps = {
  form: {},
  fields: [],
  affiliations: [],
  eventData: {},
};

export default GuestFormCard;
