export const FUNDS_LIST_API_PATH =
  'givingx/funds/?skip_pagination=True&fields=id,name,external_database_id,is_internal';
export const PG_ACCOUNT_LIST_API_PATH = 'almapay/accounts?skip_pagination=True';
export const REFRESH_POS_DEVICES = 'almapay/accounts/{}/refresh_pos'
export const CUSTOM_FORMS_LIST_API_PATH =
  // eslint-disable-next-line max-len
  'custom_forms?form_purpose=1&skip_pagination=True&is_basic_detail_required=false&fields=id,title,description,slug,fields__label,fields__field_type';
export const GET_PROFILE_LIST_BY_QUERY_API_PATH =
  'profiles?search={}&active=true&fields=formatted_name,first_name,last_name,email,id';
export const GET_TIMEZONE_LIST_API_PATH = 'timezones';
export const ZOOM_ACCOUNT_LIST_API_PATH = 'websites/zoom_accounts';

export const EVENT_TICKETS_API = '/api/v1/eventsx/{}/tickets/';
export const EVENT_EXPORT_API_PATH = '/eventsx/{}/{export_type}';
export const AVAILABLE_POS_DEVICES = '/almapay/pos/{}/available_devices';

export const EVENT_BASE_API_PATH = 'eventsx/';
export const EVENT_FILTER_SUMMARY_API_PATH = `${EVENT_BASE_API_PATH}{}/filter_summary/`;
export const EVENT_REPORT_API_PATH = `${EVENT_BASE_API_PATH}{}/report/`;
export const EVENT_GUESTS_LIST_API_PATH = `${EVENT_BASE_API_PATH}{}/guests/`;
export const EVENT_TAGS_API_PATH = `${EVENT_BASE_API_PATH}tags?skip_pagination=True`;
export const EVENT_IMAGES_API_PATH = `${EVENT_BASE_API_PATH}{}/images/`;
export const EVENT_NAME_VALIDATION_API_PATH = `${EVENT_BASE_API_PATH}name_valid?name={}`;
export const EVENT_CONTACT_PERSONS_API_PATH = `${EVENT_BASE_API_PATH}{}/contact_persons/`;
export const EVENT_TICKETS_API_PATH = `${EVENT_BASE_API_PATH}{}/tickets/`;
export const EVENT_GIFTS_API_PATH = `${EVENT_BASE_API_PATH}{}/funds/`;
export const EVENT_DISCOUNTS_API_PATH = `${EVENT_BASE_API_PATH}{}/discounts/`;
export const EVENT_SUB_EVENTS_API_PATH = `${EVENT_BASE_API_PATH}{}/agendas/`;
export const EVENT_ITINERARIES_API_PATH = `${EVENT_BASE_API_PATH}{}/itineraries/`;
export const EVENT_REGISTRATION_API_PATH = `${EVENT_BASE_API_PATH}{}/register/`;
export const EVENT_EDIT_REGISTRATION_API_PATH = `${EVENT_REGISTRATION_API_PATH}{registration_id}/get_edit_link?registered_by_admin=true`;
export const EVENT_TRANSACTIONS_API_PATH = `${EVENT_REGISTRATION_API_PATH}{registration_id}/transactions/`;
export const REGISTRANT_EMAIL_CONFIRMATION_API_PATH = `${EVENT_REGISTRATION_API_PATH}send_confirmation_email/`;
export const EVENT_REGISTRATION_DETAIL_API_PATH = `${EVENT_BASE_API_PATH}{}/registration/{registration_id}/`;
export const EVENT_DUPLICATE_API_PATH = `${EVENT_BASE_API_PATH}{}/duplicate/`;
export const EVENT_RE7_EXPORT_API_PATH = `${EVENT_BASE_API_PATH}{}/re7_export/`;
export const ANNOUNCEMENTS_API_PATH = `${EVENT_BASE_API_PATH}{}/announcements/`;
export const REGISTRATIONS_API_PATH = `${EVENT_BASE_API_PATH}{}/registrations/`;
export const EVENT_GUEST_COMMUNICATION_API_PATH =
  // eslint-disable-next-line max-len
  'emails/?group__content_type__id={content_type_id}&group__object_id={event_id}&ordering=-sent_time,-scheduled_at,-updated_at';
export const EVENT_GUEST_COMMUNICATION_SUMMARY_API_PATH =
  'emails/filter_summary?group__content_type__id={content_type_id}&group__object_id={event_id}&ordering=-sent_time&exclude_workflow_emails=true&exclude_invitee_emails=true';
export const SUB_EVENT_GUEST_COMMUNICATION_API_PATH =
  // eslint-disable-next-line max-len
  'emails/?group__content_type__id={content_type_id}&group__object_id__in={event_id}&ordering=-sent_time,-scheduled_at,-updated_at';
export const SUB_EVENT_GUEST_COMMUNICATION_SUMMARY_API_PATH =
  'emails/filter_summary?group__content_type__id={content_type_id}&group__object_id__in={event_id}&ordering=-sent_time&exclude_workflow_emails=true&exclude_invitee_emails=true';
export const GIFT_SETTINGS_API = '/eventsx/{}/gift_settings/';
export const PAGE_LIST_API = '/givingx/pages';
export const GIVING_PAYMENT_LINK_TEMPLATES_API = '/givingx/{}/payment_link_templates?skip_pagination=true';

export const EVENTS_WEBINAR_GUESTS_CHECK_IN_API = `${EVENT_BASE_API_PATH}{event_id}/webinar/check-in/`;
export const BLACKBAUD_ACCOUNTS_API_PATH = '/websites/blackbaud_accounts?fields=user_id,email';
export const PROFILE_UPDATE_API_PATH = '/profiles/<profile_id>';

export const ALMASYNC_MAPPING_PATH = '/manage/almasync/mapping/';
// eslint-disable-next-line max-len
export const ENTITY_FIELDS_PATH =
  '/manage/almacrm/entities?entities=basic_information,educations,employments,addresses,emails,other_educations,phone_numbers,affiliations';
export const SYNC_PATH = `${EVENT_BASE_API_PATH}<page_id>/almasync`;
export const SINGLE_PROFILE_PATH =
  // eslint-disable-next-line max-len
  '/profiles?id={}&page_size=5&page=1&&active=true&fields=can_email,external_database_id,formatted_name,active,email_addresses,email_addresses__status,email_addresses__is_primary,address,slug,id,role,profile_url,location,nick_name,maiden_name,present_picture,educations__class_year,listed,is_registered,phone_numbers__number,phone_numbers__type,groups';
export const GET_CHANGE_PATH = 'manage/almasync/changes';
export const TICKET_ENTRIES_PATH = '/eventsx/<event_id>/registrations/<id>/ticket_details/?skip_pagination=true';
export const GIFT_SYNC_METADATA = '/eventsx/<event_id>/get_gift_sync_metadata/';
export const PRE_REGISTRANT_FORM_API_PATH = '/eventsx/<event_id>/create_pre_registration_form/';
export const FUND_API_PATH = '/givingx/funds/';
export const GET_LIST_APPEAL_API_PATH = '/givingx/renxt/appeals/';
export const GET_LIST_PACKAGES_API_PATH = '/givingx/renxt/packages/';
export const GET_LIST_CAMPAIGN_API_PATH = '/givingx/renxt/campaigns/';
export const GET_PARTICIPATION_LEVELS_API_PATH = '/eventsx/renxt/participation_levels/';
export const VOLUNTEERS_API_PATH = '/eventsx/<event_id>/volunteers/';
export const VOLUNTEER_INVITE_EMAIL_STATUS_PATH = '/eventsx/<event_id>/bulk_send_invite_volunteer_emails_status/';
export const VOLUNTEER_SEND_API_PATH = '/eventsx/<event_id>/bulk_send_invite_volunteer_emails/';
export const REGISTRANT_EMAIL_TAX_RECEIPT_API_PATH = '/eventsx/<event_id>/registration/<registrant_id>/send_receipt/';
export const ALL_REGISTRANT_EMAIL_TAX_RECEIPT_API_PATH = '/eventsx/<event_id>/bulk_send_receipts/';
export const EVENT_METRICS_API_PATH = '/eventsx/<event_id>/metrics/';
export const EVENT_BOUGHT_TICKETS_STATS_API_PATH = '/eventsx/<event_id>/bought_tickets/stats/';
export const EVENT_SUB_EVENT_BOUGHT_TICKETS_STATS_API_PATH = '/eventsx/<event_id>/agendas/<sub_event_id>/stats/';
export const EVENT_BOUGHT_TICKETS_VARIATIONS_API_PATH = '/eventsx/<event_id>/bought_tickets/<ticket_id>/variations/';
export const EVENT_GIFT_TICKETS_BY_FUND_API_PATH = '/eventsx/<event_id>/gifts_by_fund/';
export const EVENT_SUBEVENT_METRICS_API_PATH = '/eventsx/<event_id>/agendas/<sub_event_id>/metrics/';
export const EVENT_TOUCHPOINTS_API_PATH = '/eventsx/<event_id>/touchpoints/';
export const PARTICIPANTS_API_PATH = '/eventsx/<event_id>/renxt_participants/';
export const SUB_EVENT_PARTICIPANTS_API_PATH = '/eventsx/<event_id>/renxt_sub_event_participants/';
export const UNSYNC_TICKETS_API_PATH = '/eventsx/<event_id>/unsync_tickets/';
export const USER_FEEDBACK_API_PATH =
  '/manage/user_feedback/overview?parent_object_id=<event_id>&content_type=eventxregistration';
export const LIST_USER_FEEDBACK_API_PATH =
  '/manage/user_feedbacks?parent_object_id=<event_id>&content_type=eventxregistration&skip_pagination=1';
export const CREATE_PROFILE_FROM_EXTERNAL_DATABASE_ID_API_PATH = '/profiles/create_from_external_database';
export const CHECK_SIMILAR_FORM_FIELD_NAME_URL = '/eventsx/{}/check_similar_form_field_name/';
export const IGNORE_SIMILAR_FORM_FIELDS_URL = '/eventsx/{}/ignore_duplicate_form_field_warning';
export const TRACK_ACTIONS_API_PATH = '/websites/admin_action';
export const REQUEST_FEATURE_UPGRADE_PATH = 'websites/request_upgrade';
export const GET_CUSTOM_FORM_API_PATH = '/custom_forms/<form_id>/?fields=fields';
export const UPDATE_REGISTRATION_BY_ADMIN_API_PATH = `${EVENT_BASE_API_PATH}<event_id>/registrations/<registration_id>/?registered_by_admin=true`;
export const BULK_INVITE_GUESTS_API = '/eventsx/<event_id>/create_invitees/';
export const INVITEE_FILTER_SUMMARY_API = 'eventsx/<event_id>/invitees_filter_summary/';
export const GUEST_INVITE_STATUS_HISTORY_API = 'invitee/<guest_id>/invite_status_history/';
export const UPDATE_INVITEE_DETAILS_API = 'eventsx/<event_id>/invitees/';
export const EXPORT_INVITEE_LIST = 'eventsx/<event_id>/export_invitees/';
export const GET_INVITEE_LIST = 'eventsx/<event_id>/invitees/';

// WORKFLOW ENDPOINTS
export const WORKFLOW_API_PATH = '/manage/workflows/';
export const EMAIL_GROUP_API_PATH =
  '/emails/groups/?skip_pagination=1&fields=id,name,type&content_type__isnull=True&object_id__isnull=True';
export const UPDATE_WORKFLOW_STATUS_API_PATH = '/manage/workflows/<workflow_id>/status';
export const WORKFLOW_STEP_API_PATH = '/manage/workflows/<workflow_id>/steps/<step_id>';
export const BULK_EMAILS_API_PATH = '/emails/';
