/* eslint-disable react/no-deprecated */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { times, isEmpty, keys, includes, values } from 'lodash';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
// === REDUX === //
import { setRegistrationData } from 'Src/alumniEvents/reducers/registrationDataReducer';
import { requestGuestFormLayout } from 'Src/alumniEvents/reducers/guestFormLayoutReducer';
import { setHeroBannerData } from 'Src/alumniEvents/reducers/heroBannerReducer';
import {
  REQUEST_FORM_VALIDATION,
  resetFormValidState,
  setFormValidated,
} from 'Src/alumniEvents/reducers/formValidationReducer';
// === APP UTILS AND CONSTANTS === //
import { animateMountingBlock, animateUnMountingBlock } from 'Src/common/utilities/animate_util';
import {
  convertFormLayoutToComponentFields,
  getFormValue,
  processLocationFieldsArray,
} from 'Src/common/utilities/form_utils';
import { lookAndAddAutoFillOptions } from 'Src/common/components/customFormItem/helper';
import { ALUMNI_BASE_URL } from 'Src/alumniEvents/routes';
import { PHONE_NUMBER_INPUT_PREFIX } from 'Src/adminFormsX/constants';
// === COMPONENTS === //
import GuestFormCard from './guestFormCard';

import './style.scss';

const BASE_FIELDS = ['first_name', 'last_name', 'email'];
let emailFields = [];

class GuestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
    };
  }

  componentWillMount() {
    const { registrationData, history, eventData } = this.props;
    if (isEmpty(registrationData)) {
      history.push(ALUMNI_BASE_URL.replace(':eventSlug', eventData.slug));
    } else {
      emailFields = times(registrationData.guests.length).map((index) => `email@_@${index}`);
    }
    if (eventData.should_collect_affiliation) {
      BASE_FIELDS.push('affiliation');
    }
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types, no-shadow
    const { formLayout, eventData, requestGuestFormLayout } = this.props;
    animateMountingBlock(document.getElementById('hero-banner-content'), this.animateCallBack);
    if (eventData.guest_form) {
      if (!formLayout.length) {
        requestGuestFormLayout(eventData.guest_form.id);
      } else {
        this.updateChoices(convertFormLayoutToComponentFields(formLayout));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { formLayout, resetFormValidState: resetFormValidStateL } = this.props;
    const { formLayout: nextFormLayout } = nextProps;
    if (formLayout.length !== nextFormLayout.length) {
      this.updateChoices(convertFormLayoutToComponentFields(nextProps.formLayout));
    }
    if (nextProps.formValidateState === REQUEST_FORM_VALIDATION) {
      resetFormValidStateL();
      this.validateForm();
    }
  }

  componentWillUnmount() {
    animateUnMountingBlock(document.getElementById('hero-banner-content'));
  }

  animateCallBack = () => {
    const { setHeroBannerData: setHeroBannerDataL } = this.props;
    setHeroBannerDataL({ title: 'Guest Details' });
  };

  updateChoices = async (fields) => {
    const result = fields;
    await lookAndAddAutoFillOptions(result);
    this.setState({ fields: result });
  };

  /**
   *  Method to validate the form
   * Splitting the value by @_@ to get the guest
   * adding that form and guest data for that guest
   */
  validateForm() {
    const {
      form,
      eventData,
      setRegistrationData: setRegistrationDataL,
      setFormValidated: setFormValidatedL,
    } = this.props;
    form.validateFields((err, valuesL) => {
      if (!err) {
        const { registrationData } = this.props;
        let data = {};
        keys(valuesL).forEach((value) => {
          if (value.includes(PHONE_NUMBER_INPUT_PREFIX) || value === 'first_name' || value === 'last_name') {
            return;
          }
          const splitVal = value.split('@_@');
          const val = valuesL[value];
          const index = splitVal[1];
          const key = splitVal[0];

          if (!data[index]) {
            data[index] = [];
          }
          if (includes(BASE_FIELDS, key)) {
            if (key === 'affiliation') {
              registrationData.guests[index]['affiliations'] = (val || []).map((affiliationId) => ({
                id: affiliationId,
              }));
            } else {
              registrationData.guests[index][key] = val;
            }
          } else {
            const fieldValue = getFormValue(val);
            if (fieldValue) {
              data[index].push({
                value: fieldValue,
                field_id: key,
              });
            }
          }
        });
        const processedData = {};
        keys(data).forEach((key) => {
          processedData[key] = processLocationFieldsArray(data[key]);
        });
        data = processedData;
        if (eventData.guest_form) {
          keys(data).forEach((key) => {
            registrationData.guests[key].form_entry = {
              form_id: eventData.guest_form.id,
              field_entries: data[key],
            };
          });
        }
        setRegistrationDataL(registrationData);
        setFormValidatedL();
      }
    });
  }

  validateGuestsEmail = (rule, value, callback) => {
    const { form } = this.props;
    const fields = [...emailFields];
    const index = fields.indexOf(rule.field);
    if (index > -1) {
      fields.splice(index, 1);
    }

    const formFields = form.getFieldsValue(fields);
    const emailArray = values(formFields)
      .filter((i) => i)
      .map((i) => i.toLowerCase());
    if (value && includes(emailArray, value.toLowerCase())) {
      callback('Guest email can not be same as registrant email');
    }
    callback();
  };

  render() {
    const { registrationData, form, affiliations, eventData } = this.props;
    const { fields } = this.state;
    const { guests } = registrationData;

    return (
      <div id="guest-detail-container">
        <div className="guest-forms-grid">
          {/* array of JSX items */}
          {guests &&
            guests.map((guest, index) => (
              <Form>
                <GuestFormCard
                  form={form}
                  title={<I18nCustomFormatter id="event-guest-form-card-title" values={{ index: index + 1 }} />}
                  fields={fields}
                  // title={index === 0 ? 'Your details' : `Guest ${index + 1}`}
                  record={guest}
                  index={index}
                  validateGuestsEmail={this.validateGuestsEmail}
                  affiliations={affiliations}
                  eventData={eventData}
                />
              </Form>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
  formLayout: state.guestFormLayoutReducer.data || [],
  status: state.eventDataReducer.status,
  formValidateState: state.formValidationReducer.state,
  affiliations: state.contextDataReducer.affiliations || [],
});

const mapDispatchToProps = (dispatch) => ({
  requestGuestFormLayout: (id) => dispatch(requestGuestFormLayout(id)),
  setHeroBannerData: (data) => dispatch(setHeroBannerData(data)),
  setRegistrationData: (data) => dispatch(setRegistrationData(data)),
  resetFormValidState: () => dispatch(resetFormValidState()),
  setFormValidated: () => dispatch(setFormValidated()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(GuestDetails)));
