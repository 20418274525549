import React from "react";
import { Col, Divider } from 'antd';
import { getFormattedDate } from './../../../../common/utilities/moment_util';

import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { faMapMarker, faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

const ItineraryAgendaItem = ({ agenda, isParentGuest, parentGuestName }) => {
    const attendingAgendas = agenda.data.filter(item => item.is_visible);
    return (
        <Col span={24}>
            <Divider orientation="left" className="itinerary-agenda-date">{getFormattedDate(agenda._d, 'ddd, D MMM YYYY')}</Divider>
            <div className="itinerary-agenda-wrapper">
                <Choose>
                    <When condition={attendingAgendas.length}>
                        {attendingAgendas.map((item, index) => {
                            const openTickets = !item.is_itinerary && item.open_tickets;
                            return (
                                <div key={index} className="itinerary-agenda-item">
                                    <p
                                        className="arc-H150 arc-color-B85 itinerary-agenda-name"
                                    >
                                        {item.name}
                                    </p>
                                    <div className="itinerary-agenda-section">
                                        <p
                                            className="arc-p itinerary-agenda-time arc-color-B85"
                                        >
                                            <FontAwesomeIcon className="mr6" icon={faClock}/>
                                            {`${getFormattedDate(item.start_datetime, 'hh:mm a')} - ${getFormattedDate(item.end_datetime, 'hh:mm a')}`}
                                        </p>
                                        <If condition={item.venue}>
                                            <p
                                                className="arc-p itinerary-agenda-venue arc-color-B85"
                                            >   
                                                <FontAwesomeIcon icon={faMapMarker} className="mr6"/>
                                                <span>{item.venue}</span>
                                            </p>
                                        </If>
                                    </div>
                                    <Choose>
                                        <When condition={!item.is_itinerary && openTickets}>
                                            <p
                                                className="arc-p itinerary-open-ticket arc-color-B55"
                                            >   
                                                <FontAwesomeIcon className="mr6" icon={faExclamationCircle}/>
                                                {`${isParentGuest ? 'You hold' : `${parentGuestName} holds`} ${openTickets} ticket(s) to this event`}
                                            </p>
                                        </When>
                                        <When condition={!item.is_itinerary && !item.tickets?.length}>
                                            <p
                                                className="arc-p itinerary-open-ticket arc-color-B55"
                                            >   <FontAwesomeIcon className="mr6" icon={faExclamationCircle}/>
                                                No additional tickets are required to attend this event
                                            </p>
                                        </When>
                                    </Choose>
                                </div>
                            )
                        })}
                    </When>
                    <Otherwise>
                        <p className="arc-p">No events on your schedule</p>
                    </Otherwise>
                </Choose>
            </div>
        </Col>
    )
}

export default ItineraryAgendaItem;
