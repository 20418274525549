import { combineReducers } from 'redux';
import registrationFormLayoutReducer from './registrationFormLayoutReducer';
import heroBannerDataReducer from './heroBannerReducer';
import guestFormLayoutReducer from './guestFormLayoutReducer';
import eventDataReducer from './eventDataReducer';
import registrationDataReducer from './registrationDataReducer';
import formValidationReducer from './formValidationReducer';
import contextDataReducer from './contextDataReducer';
import ticketDetailsDataReducer from './ticketDetailsDataReducer'

export default combineReducers({
    registrationDataReducer,
    registrationFormLayoutReducer,
    heroBannerDataReducer,
    guestFormLayoutReducer,
    eventDataReducer,
    formValidationReducer,
    contextDataReducer,
    ticketDetailsDataReducer
});
