/* eslint-disable no-useless-escape */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { shape, bool, number, func, arrayOf, string } from 'prop-types';
import { Form } from '@ant-design/compatible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Tooltip, Select, Checkbox } from 'antd';
import { isEmpty } from 'lodash';
import { DownOutlined } from '@ant-design/icons';

import PhoneNumberInput from 'Src/common/components/formItem/PhoneNumberInput';
import EmailInput from 'Src/common/components/formItem/EmailInput';
import CustomFormItem from 'Src/common/components/customFormItem';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle, faUsers } from '@fortawesome/pro-light-svg-icons';
import { convertFormLayoutToComponentFields } from 'Src/common/utilities/form_utils';
import { CRITERIA_CUSTOM } from 'Src/alumniEvents/constants';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
import { checkIsUserAlreadyRegisteredForEvent } from '../../../actions';
import { getFullName, getGuestDetails } from '../../../utils';
import { getFirstAndLastName } from '../../../../common/utilities/data_util';

import ThemeXModal from '../../../../common/components/themeXModal';
import ProfilesSelector from '../../../../common/components/profilesSelector';
import TextingConsentCard from './textingConsentCard';

import Header from './header/index';
import './style.scss';

const FormItem = Form.Item;

class RegistrantModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrant: this.getInitialRegistrantData(),
      loading: false,
      addedCityListener: false,
      showProfileSelectionModal: false,
      searchText: null,
      guestTextConsent: null,
      profileTextConsent: null,
      textConsentValidationError: false,
    };
    const { registrant } = this.state;
    this.state = {
      ...this.state,
      shouldShowOnAttendeeList: registrant.should_show_on_attendee_list || true,
    };
    this.handleGuestTextConsentChange = this.handleGuestTextConsentChange.bind(this);
    this.handleProfileTextConsentChange = this.handleProfileTextConsentChange.bind(this);
    this.validateTextConsent = this.validateTextConsent.bind(this);
  }

  componentDidUpdate() {
    const { addedCityListener } = this.state;
    if (!addedCityListener) {
      const placeInput = document.getElementById('city_display');
      if (placeInput) {
        const options = {
          types: ['geocode'],
        };
        const places = new window.google.maps.places.Autocomplete(placeInput, options);
        window.google.maps.event.addListener(places, 'place_changed', () => {
          this.getLatLng(places);
        });
        setTimeout(() => {
          placeInput.setAttribute('autocomplete', 'google-location');
        }, 2000);
      }
      this.setState({ addedCityListener: true });
    }
  }

  getLatLng(place) {
    const { form } = this.props;
    const googlePlace = place.getPlace();
    const d = {
      city: {
        gpid: googlePlace.place_id,
        name: googlePlace.formatted_address,
      },
    };
    d.city_display = googlePlace.formatted_address;
    form.setFieldsValue(d);
  }

  getInitialRegistrantData() {
    const { registrant, userData, isAdminAddingGuest } = this.props;
    if (isEmpty(registrant) && !isEmpty(userData) && !isAdminAddingGuest) {
      return getGuestDetails({ ...userData, phone_number: userData.mobile_number });
    }
    return registrant;
  }

  getTitle(rsvpStatus, isAdminAddingGuest) {
    if (isAdminAddingGuest) {
      return 'Create new registration';
    }
    if (rsvpStatus === 'yes') {
      return <I18nCustomFormatter id="event-registration-modal-title" />;
    }

    return 'We’ll let the hosts know';
  }

  getDescription(rsvpStatus) {
    let text = '';
    if (rsvpStatus === 'yes') {
      text = <I18nCustomFormatter id="event-registration-modal-description" />;
    } else {
      text = 'Kindly help us with your name and email so we can keep a note of your response';
    }
    return <p className="arc-p">{text}</p>;
  }

  getFormEntry(registrant, field) {
    const entry = { [field.id]: registrant[field.id] };
    if (field.type === 'google_location') {
      entry[`google_place_${field.id}`] = registrant[`google_place_${field.id}`];
    }
    return entry;
  }

  validateFullName = (rule, value, callback) => {
    value = value && value.trim();
    if (value) {
      const regex = /^[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*( [a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*)+$/;
      if (!regex.test(value.toLowerCase())) {
        callback('Please enter full name');
      }
    }
    callback();
  };

  prepareRegistrationDataFromFormData(values) {
    const { eventData } = this.props;
    const { shouldShowOnAttendeeList, guestTextConsent, profileTextConsent } = this.state;
    if (eventData.conditional_sub_event_visibility_criteria === 'custom') {
      values.pre_registration_form_entry = {
        form_id: eventData.pre_registration_form.id,
        field_entries: [
          {
            field_id: eventData.pre_registration_form.fields[0].id,
            value: values[eventData.pre_registration_form.fields[0].id],
          },
        ],
      };
    }

    const registrant = {
      ...getFirstAndLastName(values.full_name),
      ...values,
      should_show_on_attendee_list: shouldShowOnAttendeeList,
    };

    if (eventData.should_collect_affiliation) {
      registrant.affiliations = (values.affiliations || [])?.map((affiliationId) => ({
        id: parseInt(affiliationId, 10),
      }));
    }

    registrant.text_consent = guestTextConsent;
    registrant.profile_text_consent = profileTextConsent;

    return registrant;
  }

  handleGuestTextConsentChange = (value) => {
    const { profileTextConsent, textConsentValidationError } = this.state;
    const isError = textConsentValidationError && profileTextConsent === null;
    this.setState({
      guestTextConsent: value,
      textConsentValidationError: isError,
    });
  };

  handleProfileTextConsentChange = (value) => {
    const { guestTextConsent, textConsentValidationError } = this.state;
    const isError = textConsentValidationError && guestTextConsent === null;
    this.setState({
      profileTextConsent: value,
      textConsentValidationError: isError,
    });
  };

  validateTextConsent() {
    const { eventData } = this.props;
    if (!window?.isTextCommunicationEnabled || !eventData?.should_collect_text_consent || this.props.isAdminAddingGuest) {
      return false;
    }
    const { guestTextConsent, profileTextConsent } = this.state;
    if (guestTextConsent === null || profileTextConsent === null) {
      this.setState({ textConsentValidationError: true });
      return true;
    }
    return false;
  }

  validateRegistrant(e) {
    e.preventDefault();
    const { form, eventId, handleRegistrant } = this.props;
    const { validateFields } = form;
    const { loading } = this.state;
    form.validateFields(['full_name']);
    validateFields((err, values) => {
      const isTextConsentError = this.validateTextConsent();
      if (isTextConsentError && values?.phone_number_input) {
        return;
      }
      if (!err && !loading) {
        this.setState({ loading: true });
        const registrant = this.prepareRegistrationDataFromFormData(values);
        if (values.email) {
          checkIsUserAlreadyRegisteredForEvent(eventId, values.email).then((res) => {
            handleRegistrant(registrant, res.data.is_already_registered);
          });
        } else {
          setTimeout(() => {
            handleRegistrant(registrant, false);
          }, 1000);
        }
      }
    });
  }

  toggleProfileSelectionModal(value, query) {
    this.setState({ showProfileSelectionModal: value, searchText: query });
  }

  selectRegistrant(profile) {
    const { form } = this.props;
    const { setFieldsValue } = form;
    if (profile) {
      setFieldsValue({
        full_name: profile.formatted_name,
        email: profile.email,
      });
    }
  }

  render() {
    const { form, handleClose, isEditMode, eventData, affiliations, isAdminAddingGuest, rsvpStatus } = this.props;
    const { getFieldDecorator } = form;
    const { registrant, loading, showProfileSelectionModal, searchText, shouldShowOnAttendeeList } = this.state;
    const guestCount = eventData.count_guests;
    const nonAnonymousGuestCount = eventData.attending_guest_profiles.filter(
      (profile) => profile.should_show_on_attendee_list,
    ).length;

    return (
      <ThemeXModal
        title={this.getTitle(rsvpStatus, isAdminAddingGuest)}
        Description={this.getDescription(rsvpStatus)}
        visible
        wrapClassName="registrant-modal-body"
        handleCancel={() => handleClose()}
        closable={false}
        maskClosable={false}
        header={<Header goBack={() => handleClose()} />}>
        <Form onSubmit={(e) => this.validateRegistrant(e)} colon={false}>
          <div>
            <FormItem label="Full Name">
              {getFieldDecorator('full_name', {
                initialValue: getFullName(registrant),
                rules: [
                  {
                    required: true,
                    message: 'Please enter your name',
                  },
                  {
                    validator: this.validateFullName,
                  },
                ],
                validateTrigger: 'onBlur',
              })(
                <Input
                  readOnly={isEditMode}
                  placeholder="e.g. John Smith"
                  autoComplete="name"
                  aria-required="true"
                  maxLength="248"
                  addonAfter={
                    <If condition={isAdminAddingGuest}>
                      <FontAwesomeIcon
                        icon={faSearch}
                        onClick={() => this.toggleProfileSelectionModal(true, form.getFieldValue('full_name'))} />
                    </If>
                  } />,
              )}
            </FormItem>
            <EmailInput
              validateUsingApi={window.isEmailValidationEnabled}
              form={form}
              formItem={{
                key: 'email',
                label: 'Email',
                required: !isAdminAddingGuest,
                placeholder: 'e.g. jsmith@email.com',
                addonAfter: (
                  <If condition={isAdminAddingGuest}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      onClick={() => this.toggleProfileSelectionModal(true, form.getFieldValue('email'))} />
                  </If>
                ),
              }}
              formRecord={{
                email: registrant.email,
              }} />
            <If condition={rsvpStatus === 'yes'}>
              <If condition={eventData.should_collect_affiliation}>
                <FormItem
                  label={
                    <span>
                      <span style={{ marginRight: '0.2rem' }}>
                        <I18nCustomFormatter id="event-payment-modal-affiliation-label" />
                      </span>
                      <Tooltip title={`How are you affiliated with ${window.collegeName}?`}>
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </Tooltip>
                    </span>
                  }>
                  {getFieldDecorator('affiliations', {
                    initialValue: registrant.affiliations?.map((affiliation) => affiliation.id),
                  })(
                    <Select
                      className="registration-affiliation-select"
                      placeholder="e.g. Alumni, Student, Faculty, etc."
                      mode="multiple"
                      allowClear
                      showArrow
                      suffixIcon={<DownOutlined />}
                      style={{ width: '100%' }}
                      direction="vertical"
                      defaultValue={[]}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {affiliations.map((affiliation) => (
                        <Select.Option value={affiliation.id} key={affiliation.id}>
                          {affiliation.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </If>
              <PhoneNumberInput
                form={form}
                formItem={{
                  key: 'phone_number',
                  label: 'Phone',
                  required: !isAdminAddingGuest,
                  placeholder: 'Enter phone number',
                  maxLength: 20,
                }}
                formRecord={{
                  phone_number: registrant.phone_number,
                }} />
              <If condition={window?.isTextCommunicationEnabled && eventData.should_collect_text_consent && form.getFieldValue('phone_number_input')}>
                <TextingConsentCard
                  guestTextConsent={this.state.guestTextConsent}
                  handleGuestTextConsentChange={this.handleGuestTextConsentChange}
                  profileTextConsent={this.state.profileTextConsent}
                  handleProfileTextConsentChange={this.handleProfileTextConsentChange}
                  isError={this.state.textConsentValidationError} />
              </If>
              <If condition={window.isTaxReceiptEnabled || window.areAddressFieldsEnabled}>
                <FormItem label="Address Line 1">
                  {getFieldDecorator('address_line1', {
                    initialValue: registrant.address_line1,
                    rules: [
                      {
                        required: !isAdminAddingGuest,
                        message: 'Please enter address line 1!',
                      },
                    ],
                    validateTrigger: 'onBlur',
                  })(<Input readOnly={isEditMode} placeholder="Address Line 1" />)}
                </FormItem>
                <FormItem label="Address Line 2">
                  {getFieldDecorator('address_line2', {
                    initialValue: registrant.address_line2,
                    validateTrigger: 'onBlur',
                  })(<Input readOnly={isEditMode} placeholder="Address Line 2" />)}
                </FormItem>
                <FormItem label="Zip Code">
                  {getFieldDecorator('zip_code', {
                    initialValue: registrant.zip_code,
                    rules: [
                      {
                        required: !isAdminAddingGuest,
                        message: 'Please enter zip code!',
                      },
                    ],
                    validateTrigger: 'onBlur',
                  })(<Input readOnly={isEditMode} placeholder="Zip Code" />)}
                </FormItem>
                <Form.Item color={false} label="City">
                  {getFieldDecorator('city_display', {
                    initialValue: registrant.city && registrant.city.formatted_address,
                    rules: [
                      {
                        required: !isAdminAddingGuest,
                        message: 'Please enter your city',
                      },
                    ],
                  })(<Input placeholder="City" autoComplete="google-location" />)}
                </Form.Item>
                <Form.Item style={{ display: 'none' }}>
                  {getFieldDecorator('city', {
                    initialValue: registrant.city,
                  })(<Input type="hidden" />)}
                </Form.Item>
              </If>
              <If
                condition={
                  eventData.conditional_sub_event_visibility_criteria === CRITERIA_CUSTOM
                  && !isEmpty(eventData.pre_registration_form)
                }>
                {convertFormLayoutToComponentFields(eventData.pre_registration_form.fields).map((field) => (
                  <CustomFormItem
                    key={field.id}
                    form={form}
                    formInput={field}
                    formRecord={this.getFormEntry(registrant, field)}
                    metadata={{
                      source_type: 'customform',
                      source_id: eventData && eventData.pre_registration_form && eventData.pre_registration_form.id,
                      access_level: 'protected',
                    }} />
                ))}
              </If>

              <If condition={eventData.is_guest_count_shown_to_users && !eventData.is_accompanying_guest_allowed}>
                <div
                  className="ml24 mr24 mt8 mb8 p16"
                  id="guest-privacy-container"
                  role="group"
                  aria-label="Guest Privacy">
                  <p className="arc-p arc-font-weight-bold arc-color-gray8">GUEST PRIVACY</p>
                  <Checkbox
                    className="arc-p mt8 mb8 arc-font-weight-medium"
                    onChange={(e) => this.setState({ shouldShowOnAttendeeList: e.target.checked })}
                    checked={shouldShowOnAttendeeList}>
                    Let others know I will be attending
                  </Checkbox>
                  <span id="guest-privacy-description" className="arc-color-B55 arc-font-weight-400">
                    Encourage others to join in by adding your name. If you&apos;d prefer to keep your participation
                    private, we&apos;re happy to list you as &apos;anonymous&apos;
                  </span>
                </div>
                <If condition={eventData.is_guest_count_shown_to_users && guestCount}>
                  <div
                    className="mb48 ml48 mr48 arc-color-B55 arc-font-weight-400 arc-text-align-c"
                    id="guest-attendee-list-description">
                    <FontAwesomeIcon icon={faUsers} className="mr8" />
                    <span>
                      <Choose>
                        <When condition={guestCount <= 2 && nonAnonymousGuestCount === guestCount}>
                          <I18nCustomFormatter
                            id="event-guest-is-attending"
                            values={{
                              full_name: eventData.attending_guest_profiles[0].full_name,
                              second_full_name:
                                nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].full_name : null,
                              non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                            }} />
                        </When>
                        <When condition={nonAnonymousGuestCount === 0}>
                          <I18nCustomFormatter
                            id="guest-counts-attending-numbers"
                            values={{
                              guests: guestCount,
                            }} />
                        </When>
                        <Otherwise>
                          <span className="guest-list-trigger">
                            <I18nCustomFormatter
                              id="event-guests-more-than-one-prefix"
                              values={{
                                full_name: eventData.attending_guest_profiles[0].first_name,
                                second_full_name:
                                  nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].first_name : null,
                                non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                              }} />
                            {' '}
                            <I18nCustomFormatter
                              id="guest-counts-attending-link"
                              values={{
                                guests: guestCount - Math.min(nonAnonymousGuestCount, 2),
                              }} />
                            {' '}
                          </span>
                          <I18nCustomFormatter
                            id="guest-counts-attending-text"
                            values={{
                              guests: guestCount - Math.min(nonAnonymousGuestCount, 2),
                            }} />
                        </Otherwise>
                      </Choose>
                    </span>
                  </div>
                </If>
              </If>
            </If>
            <p className="required-field-text">
              <span className="arc-color-error">*</span>
              {' '}
              Indicates a required field
            </p>
            <p className="recaptcha-terms-privacy-text">
              This site is protected by reCAPTCHA and the Google
              {' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
                className="arc-focus-outline">
                Privacy Policy
              </a>
              {' '}
              and
              {' '}
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noreferrer"
                className="arc-focus-outline">
                Terms of Service
              </a>
              {' '}
              apply.
            </p>
          </div>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            className="arc-modal-footer-full-width-btn arc-focus-outline">
            Register
          </Button>
        </Form>
        <If condition={showProfileSelectionModal}>
          <ProfilesSelector
            initialLoad
            query={searchText}
            visible={showProfileSelectionModal}
            handleHideModal={() => this.toggleProfileSelectionModal(false)}
            handleOkModal={(profile) => this.selectRegistrant(profile)}
            endpoint="events/profiles" />
        </If>
      </ThemeXModal>
    );
  }
}

RegistrantModal.propTypes = {
  isEditMode: bool,
  eventId: number.isRequired,
  registrant: shape,
  userData: shape,
  isAdminAddingGuest: bool,
  rsvpStatus: string,
  handleRegistrant: func.isRequired,
  handleClose: func.isRequired,
  affiliations: arrayOf(),
  eventData: shape(),
};

RegistrantModal.defaultProps = {
  isEditMode: false,
  isAdminAddingGuest: false,
  registrant: {},
  userData: {},
  rsvpStatus: 'yes',
  affiliations: [],
  eventData: {},
};

export default Form.create()(RegistrantModal);
