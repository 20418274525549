import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';
import { slice } from 'lodash';
import { Input, Empty } from 'antd';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import './style.scss';

const SelectFilter = ({ options, value, onChange, question, onClose }) => {
  const [selectedValues, setSelectedValues] = useState(value || []);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setSelectedValues(value || []);
  }, [value]);

  const handleSelect = (optionValue) => {
    let newValues;

    if (optionValue === 'All') {
      newValues = [];
    } else {
      const isValueSelected = selectedValues.includes(optionValue);

      if (isValueSelected) {
        newValues = selectedValues.filter((val) => val !== optionValue);
      } else {
        newValues = [...selectedValues, optionValue];
      }
    }

    setSelectedValues(newValues);
    onChange(newValues);

    if (onClose) {
      onClose();
    }
  };

  const isSelected = (optionValue) => {
    if (optionValue === 'All') {
      return selectedValues.length === 0;
    }
    return selectedValues.includes(optionValue);
  };

  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div className="select-filter-modal">
      <div className="p12 pb0">
        <p className="arc-color-gray8 arc-p mb4 arc-font-weight-medium">{question}</p>
        <If condition={options.length > 2}>
          <div className="search-container">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="filter-search-input"
            />
          </div>
        </If>
      </div>
      <ul className="filter-options-list">
        <If condition={!searchQuery}>
          <div
            className={`filter-option ${isSelected('All') ? 'selected' : ''}`}
            onClick={() => handleSelect('All')}
            onKeyDown={(e) => handleEnterKeyPress(e, () => handleSelect('All'))}
            role="button"
            tabIndex={0}>
            <span className="option-text">All</span>
            {isSelected('All') && <CheckOutlined className="arc-color-primary" />}
          </div>
        </If>

        <Choose>
          <When condition={filteredOptions.length > 0}>
            {slice(filteredOptions, 0, 8).map((option) => (
              <div
                key={option.value}
                className={`filter-option ${isSelected(option.value) ? 'selected' : ''}`}
                onClick={() => handleSelect(option.value)}
                onKeyDown={(e) => handleEnterKeyPress(e, () => handleSelect(option.value))}
                role="button"
                tabIndex={0}>
                <span className="arc-p option-text arc-color-gray8">{option.label}</span>
                {isSelected(option.value) && <CheckOutlined className="arc-color-primary" />}
              </div>
            ))}
          </When>
          <Otherwise>
            <div className="pt20 pb20">
              <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </Otherwise>
        </Choose>
      </ul>
    </div>
  );
};

SelectFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

SelectFilter.defaultProps = {
  value: [],
  onClose: () => {},
};

export default SelectFilter;
