import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileScreenButton } from '@fortawesome/pro-light-svg-icons';
import { Radio } from 'antd';

function TextingConsentCard({
  isError = false,
  guestTextConsent,
  handleGuestTextConsentChange,
  profileTextConsent,
  handleProfileTextConsentChange,
}) {
  const onGuestConsentChange = (e) => {
    handleGuestTextConsentChange(e.target.value);
  };

  const onProfileConsentChange = (e) => {
    handleProfileTextConsentChange(e.target.value);
  };

  return (
    <>
      <div className={`text-consent-card ${isError ? 'error' : ''}`}>
        <p className="arc-H150 mb4">
          <FontAwesomeIcon icon={faMobileScreenButton} className="mr6" />
          Stay informed
        </p>
        <p className="arc-support mb8">Would you like to receive text message updates for this event?</p>
        <Radio.Group onChange={onGuestConsentChange} value={guestTextConsent}>
          <Radio value={true}>YES</Radio>
          <Radio value={false}>NO</Radio>
        </Radio.Group>
        <p className="arc-support mt16 mb8">
          Would you like to receive text message updates for future events, giving, and community news?
        </p>
        <Radio.Group onChange={onProfileConsentChange} value={profileTextConsent}>
          <Radio value={true}>YES</Radio>
          <Radio value={false}>NO</Radio>
        </Radio.Group>
        <p className="disclaimer-wrapper arc-d-flex arc-flex-direction-column mt12">
          <p className="disclaimer-list-item">
            <p>*</p> Unsubscribe at any time to all, or specific kinds of updates
          </p>
          <p className="disclaimer-list-item">
            <p>*</p> Message frequency may vary, standard reply charges may apply
          </p>
        </p>
      </div>
      <If condition={isError}>
        <p className="mb12 mr24 ml24 text-consent-error-msg">Please answer all required questions to proceed</p>
      </If>
    </>
  );
}

export default TextingConsentCard;
