/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-lonely-if */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable complexity */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SettingOutlined } from '@ant-design/icons';
import { Button, message, Menu, Dropdown, Modal } from 'antd';
import { isEmpty, includes, cloneDeep } from 'lodash';
// === COMMON === //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelopeOpen } from '@fortawesome/pro-light-svg-icons';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { TOUCHPOINTS } from 'Src/alumniEvents/constants';
import AlmasightsContext from 'Src/common/context/almasights';
import initializeCaptchaV2 from 'Src/common/utilities/captcha_util';
import { getProfileByEmail } from '../../../common/actions';
import { REDUX_STATUSES, SIGNUP_MODAL_STEPS } from '../../../common/constants';
import { animateMountingBlock, animateUnMountingBlock } from '../../../common/utilities/animate_util';
import { getUrlParam } from '../../../common/utilities/data_util';
// === ACTIONS === //
import { registerGuest, cancelRegistration, sendInviteEmail, resendConfirmationEmail } from '../../actions';
// === REDUX === //
import { setRegistrationData } from '../../reducers/registrationDataReducer';
import { setEventData } from '../../reducers/eventDataReducer';
import { setHeroBannerData } from '../../reducers/heroBannerReducer';
import { setContextData } from '../../reducers/contextDataReducer';
// === APP UTILS AND CONSTANTS === //
import { getGuestDetails } from '../../utils';
import {
  TICKETS_VIEW_URL,
  GUEST_VIEW_URL,
  BOOKING_FORM_VIEW_URL,
  ADMIN_BASE_URL,
  GUEST_KIT_VIEW_URL,
  COMPOSE_EMAIL_URL,
  CONFIRMATION_VIEW_URL,
} from '../../routes';
// === COMPONENTS === //
import DetailBar from './DetailBar';
import DetailBox from './DetailBox';
import RegistrantModal from '../common/registrantModal';
import GuestCountModal from '../common/guestCountModal';
import NotificationReminderModal from '../common/notificationReminderModal';
import ThemeXSignInModal from '../../../common/components/themeXSignInModal';
import ModifyRegistrationEmailModal from '../common/modifyRegistrationEmailModal';
import EditRegistrationActionModal from '../common/editRegistrationActionModal';
import CartModal from '../common/cartModal';
import GuestListModal from './DetailBar/GuestListModal';
import ThemeXShareModal from '../../../common/components/themeXShareModal';

import '../../style.scss';
import { setTicketDetailsData } from '../../reducers/ticketDetailsDataReducer';

let registrantEmail = null;
let rsvpStatus = null;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      isEditMode: props.contextData.isEditMode,
      showRegistrantModal: false,
      showGuestModal: false,
      showEditRegistrationActionModal: false,
      showModifyRegistrationModal: false,
      showCheckoutModal: false,
      rsvpStatus: null,
      showGuestListModal: false,
      lookupProfile: {},
      showSignInModal: false,
      showShareModal: false,
      touchpointCreated: false,
      showNotificationReminderModal: false,
      notificationEmail: null,
      stateRegistrationData: {},
      showCancelNotificationModal: false,
    };
  }

  componentDidMount() {
    animateMountingBlock(document.getElementById('hero-banner-content'), this.animateCallBack);
  }

  componentWillUnmount() {
    animateUnMountingBlock(document.getElementById('hero-banner-content'));
  }

  animateCallBack = () => {
    const { registrationData } = this.props;
    this.props.setHeroBannerData({ hideHeroBackLink: true, redirect_link: '/events', back_text: 'All events' });

    const { contextData, eventData } = this.props;

    if (contextData.isEventAdmin && getUrlParam('add_guest') === 'true') {
      rsvpStatus = 'yes';
      this.setState({ showRegistrantModal: true, rsvpStatus });
    }

    if (getUrlParam('edit_registration') === 'true' && contextData.isEditMode) {
      rsvpStatus = registrationData.rsvp_status;
      this.setState({ showEditRegistrationActionModal: true, rsvpStatus });
    }

    if (
      getUrlParam('register') === 'true' &&
      !isEmpty(contextData.userData) &&
      eventData.is_registration_open &&
      eventData.is_active &&
      !contextData.isEditMode &&
      !eventData.is_past &&
      !eventData.external_registration_url
    ) {
      rsvpStatus = 'yes';
      this.setState({ showRegistrantModal: true, rsvpStatus });
    }
  };

  /**
   * Method to handle the registrant modal close action,
   * If user is admin and registration data is there which mean admin is logged in
   * or has hash edit link, so saving back data to redux, because on click on
   * registering for some one else we flushed the registrationData from redux
   */
  handleRegistrantCloseAction() {
    let { isEditMode } = this.state;
    const { contextData } = this.props;

    if (contextData.isEventAdmin && contextData.isAdminAddingGuest) {
      this.props.setContextData({ isAdminAddingGuest: false });
      this.props.setRegistrationData(contextData.initialRegistrationData);
      if (window.registrationData) {
        isEditMode = true;
      }
    }
    this.setState({ showRegistrantModal: false, isEditMode });
  }

  /**
   * Method to handle the register button based on event type
   *  */
  handleRegisterButtonAction(selectedRsvpStatus, isAdminAddingGuest = false, showEditModal = false) {
    const { eventData, registrationData } = this.props;

    const isRegistrationExist = !!registrationData.id;
    this.setState({ rsvpStatus: selectedRsvpStatus });
    rsvpStatus = selectedRsvpStatus;

    if (eventData.external_registration_url) {
      this.setState({ processing: true });
      window.location = eventData.external_registration_url;
    } else if (isAdminAddingGuest) {
      this.props.setContextData({ isAdminAddingGuest });
      this.props.setRegistrationData({});
      this.setState({
        showRegistrantModal: true,
        isEditMode: false,
      });
    } else {
      /**
       * If registration exist
       * * * If rsvp status is YES only then it means either user  didn't change the rsvp status
       * * * or user changed from NO -> YES or MAYBE -> YES
       * * * else user changed from NO -> YES or MAYBE -> YES
       * else
       * * * show registrant modal
       */
      if (isRegistrationExist) {
        if (rsvpStatus === 'yes') {
          if (rsvpStatus === registrationData.rsvp_status || showEditModal) {
            this.setState({ showEditRegistrationActionModal: true });
          } else {
            this.openNonLoginOrGuestOrLoginModalOrRegister();
          }
        } else {
          const registration = { rsvp_status: rsvpStatus, id: registrationData.id };
          this.updateRSVPStatus(registration);
        }
      } else {
        this.setState({ showRegistrantModal: true });
      }
    }
  }

  /**
   * Method to cancel the registration
   */
  cancelRegistration() {
    const { eventData, registrationData, contextData } = this.props;
    this.setState({ processing: true, showEditRegistrationActionModal: false });
    return cancelRegistration(
      eventData.id,
      registrationData.id,
      contextData.registrationHash,
      contextData.isAdminEditingGuest,
    )
      .then((response) => {
        const { data } = response;

        data.guests = data.guests.map((g, idx) => {
          g.tmp_id = idx;
          return g;
        });

        this.props.setRegistrationData(data);
        this.setState({ processing: false });
        message.success('Your registration has been cancelled.');
      })
      .catch(() => {
        this.setState({ processing: false });
      });
  }

  toggleCancelReminderModal(val) {
    this.setState({ showCancelNotificationModal: val });
  }

  /**
   * Method to set the registrant
   * if rsvp status is NO or MAYBE
   * * * Just register the guest with rsvp status
   * else
   * * * follow the remaining steps
   */
  setRegistrant(registrant, isAlreadyRegistered) {
    registrantEmail = registrant.email;
    const { contextData } = this.props;
    const { isAdminAddingGuest, isAdminEditingGuest } = contextData;
    const newRegistrationData = {
      ...registrant,
      guests: [{ ...registrant, tmp_id: 0, is_primary: true }],
      added_by_admin: isAdminAddingGuest,
    };

    /**
     * This will come in case user is not logged in and try to register with same email
     * which is already registered
     */
    if (isAlreadyRegistered) {
      this.props.setRegistrationData(newRegistrationData);
      this.setState({
        showRegistrantModal: false,
        showModifyRegistrationModal: true,
      });
    } else if (rsvpStatus === 'maybe') {
      this.updateRSVPStatus(newRegistrationData);
    } else if (rsvpStatus === 'no') {
      if (registrant.email && (isAdminAddingGuest || isAdminEditingGuest)) {
        this.setState({
          showNotificationReminderModal: true,
          notificationEmail: registrant.email,
          stateRegistrationData: newRegistrationData,
        });
      } else {
        this.updateRSVPStatus(newRegistrationData);
      }
    } else {
      this.props.setRegistrationData(cloneDeep(newRegistrationData));
      this.props.setTicketDetailsData({});
      this.openNonLoginOrGuestOrLoginModalOrRegister();
    }
  }

  // Method to update the RSVP status
  updateRSVPStatus(registrationData, cbSuccess) {
    const { contextData, eventData } = this.props;
    this.setState({
      processing: true,
      showRegistrantModal: false,
      showNotificationReminderModal: false,
      stateRegistrationData: {},
      notificationEmail: null,
    });
    registrationData.rsvp_status = rsvpStatus;
    if (rsvpStatus === 'no') {
      delete registrationData.pre_registration_form_entry
    }
    const eventId = eventData?.id;

    this.injectCaptcha(registrationData, (regData) => {
      registerGuest({
        registrationData: regData,
        eventId,
        hashToken: contextData.registrationHash,
        requestMethod: 'PATCH',
        invitationHash: contextData.invitationHash,
      })
        .then((response) => {
          if (cbSuccess) {
            cbSuccess();
          }
          const { data } = response;
          data.guests.forEach((guest, index) => {
            guest.tmp_id = index;
          });
          this.props.setRegistrationData(data);
          this.props.setContextData({
            registrationHash: data.edit_hash,
          });
          this.setState({ processing: false });
        })
        .catch((err) => {
          if (err.response?.data?.tickets?.length) {
            message.error(err.response?.data?.tickets[0]);
          } else if (err.response?.data?.first_name) {
            message.error(`First Name: ${err.response.data.first_name}`);
          } else if (err.response?.data?.last_name) {
            message.error(`Last Name: ${err.response.data.last_name}`);
          }
          this.setState({ processing: false });
        });
    });
  }

  /**
   * If non login registration is disabled and user is not logged in then show and not edit mode --> login modal
   * If guest count enabled or user is non logged in then show --> guest count modal
   * If not guest count and not login modal --> set self guest and redirect to route
   */
  openNonLoginOrGuestOrLoginModalOrRegister() {
    const { eventData, contextData, registrationData } = this.props;
    const { userData } = contextData;
    const isNonLoggedInRegistrationEnabled = eventData.is_registration_enabled_without_login;
    const isLoginRequired = isEmpty(userData) && !isNonLoggedInRegistrationEnabled && !this.state.isEditMode;
    const isAccompanyingGuestEnabled = eventData.is_accompanying_guest_allowed && !isLoginRequired;
    this.setState({
      showEditRegistrationActionModal: false,
    });
    if (isLoginRequired) {
      getProfileByEmail(registrationData.email).then((response) => {
        const profile = response.data.length === 1 ? response.data[0] : {};
        // Which mean profile email is different from user email
        if (profile.email && profile.email !== registrationData.email) {
          profile.email = registrationData.email;
        }
        this.setState({
          showRegistrantModal: false,
          lookupProfile: profile,
          showSignInModal: true,
        });
      });
    } else {
      this.setState({
        showRegistrantModal: false,
        showGuestModal: isAccompanyingGuestEnabled,
      });
      if (!isAccompanyingGuestEnabled) {
        const data = this.getUpdatedRegistrationData(registrationData.guests);
        this.redirectOrRegister(data);
      }
    }
  }

  /**
   * Method to update registration based on guests
   * @param {array of guests} guests
   */
  updateRegistrationBasedOnGuests(guests) {
    const data = this.getUpdatedRegistrationData(guests);
    this.setState({ showGuestModal: false });
    this.redirectOrRegister(data);
  }

  handleGuestCountModalClose() {
    this.setState({ showGuestModal: false });
  }

  /**
   * Method to update the registration data
   * @param {array} newGuests
   * This will add the mandatory tickets automatically based on guests
   * Adding self guest or first guest as registrant
   */
  getUpdatedRegistrationData(newGuests) {
    const { registrationData } = this.props;
    const { isEditMode } = this.state;
    const { mandatoryTickets } = this.props.contextData;
    const existingGuests = registrationData.guests || [];
    const guestMandatoryTickets = mandatoryTickets.map((ticket) => ({
      ticket: { id: ticket.id },
      quantity: newGuests.length,
    }));
    const updatedRegistration = {
      guest_count: newGuests.length,
      guests: newGuests.map((guest, index) => {
        const existingGuest = existingGuests.filter((guestL) => guestL.tmp_id === index)[0];
        let guestTickets = [];
        if (existingGuest && existingGuest.tickets && existingGuest.tickets.length) {
          guestTickets = existingGuest.tickets;
        } else {
          guestTickets = mandatoryTickets.map((ticket) => ({ ticket: { id: ticket.id } }));
        }
        return {
          ...(existingGuest || {}),
          first_name: guest.first_name,
          last_name: guest.last_name,
          tmp_id: index,
          tickets: guestTickets,
          should_show_on_attendee_list: guest.should_show_on_attendee_list,
          ...(index === 0 ? { email: isEditMode ? existingGuest.email : registrantEmail } : {}),
        };
      }),
    };
    if (isEditMode) {
      const mandatoryTicketIds = mandatoryTickets.map((ticket) => ticket.id);
      const nonMandatoryTickets = registrationData.tickets.filter(
        (item) => !includes(mandatoryTicketIds, item.ticket.id),
      );
      updatedRegistration.tickets = [...nonMandatoryTickets, ...guestMandatoryTickets];
    } else {
      updatedRegistration.tickets = guestMandatoryTickets;
    }
    const data = {
      ...this.props.registrationData,
      ...{ ...updatedRegistration, ...getGuestDetails(updatedRegistration.guests[0]) },
    };
    return data;
  }

  injectCaptcha(registrationData, callback) {
    if (!registrationData.id && window.captchaEnabled) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(window.googleCaptchaKey, { action: 'submit' }).then((token) => {
          registrationData.captcha_token = token;
          callback(registrationData);
        });
      });
    } else {
      callback(registrationData);
    }
  }

  handleRegistrationData(response, registrationData) {
    const { data } = response;
    let registrationHashLink = window.location.search;
    data.guests.forEach((guest, index) => {
      guest.tmp_id = index;
    });
    if (!registrationData.id) {
      this.props.setContextData({
        registrationHash: response.edit_hash,
      });
      registrationHashLink = `?r=${data.edit_key}`;
    }
    const addGuestParam = getUrlParam('add_guest');
    const fromAdminParam = getUrlParam('from_admin');

    if (addGuestParam === 'true') {
      registrationHashLink = `${registrationHashLink}&add_guest=true`;
    }
    if (fromAdminParam === 'true') {
      registrationHashLink = `${registrationHashLink}&from_admin=true`;
    }

    this.props.setRegistrationData(data);
    this.redirectToConfirmation(registrationHashLink);
  }

  /**
   * Method to redirect to route or call register method,
   * If event is ticked event - and event tickets not equal to mandatory tickets then redirect to tickets page
   * because in case of event tickets === mandatory tickets there is no purpose to go to tickets page,
   * If event guest registration enabled redirect to guest form page
   * If event has booking form redirect to form page
   * else register guest
   * @param {object} registrationData
   */
  redirectOrRegister(registrationData) {
    const { eventData, contextData } = this.props;
    const { isAdminAddingGuest, isAdminEditingGuest } = contextData;
    registrationData.added_by_admin = isAdminAddingGuest || isAdminEditingGuest;
    this.props.setRegistrationData(registrationData);
    if (
      contextData.isTicketedEvent &&
      (eventData.tickets.length !== contextData.mandatoryTickets.length || eventData.funds.length)
    ) {
      this.props.history.push({
        pathname: TICKETS_VIEW_URL.replace(':eventSlug', eventData.slug),
        search: window.location.search,
      });
    } else if (
      (eventData.is_accompanying_guest_allowed && registrationData.guests.length > 1) ||
      eventData.guest_form
    ) {
      this.props.history.push({
        pathname: GUEST_VIEW_URL.replace(':eventSlug', eventData.slug),
        search: window.location.search,
      });
    } else if (eventData.registration_form) {
      this.props.history.push({
        pathname: BOOKING_FORM_VIEW_URL.replace(':eventSlug', eventData.slug),
        search: window.location.search,
      });
    } else if (contextData.isTicketedEvent) {
      this.setState({ showCheckoutModal: true });
    } else {
      this.setState({ processing: true });
      registrationData.rsvp_status = rsvpStatus;

      this.injectCaptcha(registrationData, (regData) => {
        let payload = { registrationData: regData, eventId: eventData?.id, hashToken: contextData.registrationHash };
        if (window.location.href.includes('i=')) {
          const invitationHash = window.location.href.split('i=')[1];
          payload = { ...payload, invitationHash };
        }
        registerGuest(payload)
          .then((response) => {
            this.handleRegistrationData(response, regData);
          })
          .catch((err) => {
            if (err.response.status === 400 && err.response.data.detail === 'Captcha Low Score') {
              initializeCaptchaV2((token) => {
                regData.captcha_token = token;
                regData.captcha_v2 = true;
                registerGuest({
                  registrationData: regData,
                  eventId: eventData?.id,
                  hashToken: contextData.registrationHash,
                })
                  .then(async (response) => {
                    this.handleRegistrationData(response, regData);
                  })
                  .catch((errV2) => {
                    if (errV2.response?.data?.tickets?.length) {
                      message.error(errV2.response?.data?.tickets[0]);
                    }
                    this.setState({ processing: false });
                  });
              });
            } else if (err.response?.data?.tickets?.length) {
              message.error(err.response?.data?.tickets[0]);
              this.setState({ processing: false });
            } else if (err.response?.data?.first_name) {
              message.error(`First Name: ${err.response.data.first_name}`);
              this.setState({ processing: false });
            } else if (err.response?.data?.last_name) {
              message.error(`Last Name: ${err.response.data.last_name}`);
              this.setState({ processing: false });
            } else {
              this.setState({ processing: false });
            }
          });
      });
    }
  }

  sendEditEmail() {
    resendConfirmationEmail({
      eventId: window.eventData.id,
      registrantEmail: window.guestData.email,
      isEditRegistration: true,
    })
      .then(() => {
        message.success('Email sent successfully');
      })
      .catch(() => {
        message.error('Something went wrong please try again');
      });
  }

  redirectToConfirmation(registrationHashLink = window.location.search) {
    this.props.history.push({
      pathname: CONFIRMATION_VIEW_URL.replace(':eventSlug', this.props.eventData.slug),
      search: registrationHashLink,
    });
  }

  redirectToGuestKit(registrationHashLink = window.location.search) {
    this.props.history.push({
      pathname: GUEST_KIT_VIEW_URL.replace(':eventSlug', this.props.eventData.slug),
      search: registrationHashLink,
    });
  }

  redirectToAdmin() {
    window.location.href = ADMIN_BASE_URL.replace('{}', this.props.eventData.id);
  }

  handleInviteGuestAction() {
    sendInviteEmail(this.props.eventData.id).then((response) => {
      window.open(COMPOSE_EMAIL_URL.replace('{}', response.data.email_id), '_blank');
    });
  }

  hideModifyRegistrationModal() {
    this.setState({ showModifyRegistrationModal: false });
  }

  hideEditRegistrationActionModal() {
    this.setState({ showEditRegistrationActionModal: false });
  }

  toggleGuestListModal() {
    this.setState((prevState) => ({ showGuestListModal: !prevState.showGuestListModal }));
  }

  hideSignInModal() {
    this.setState({ showSignInModal: false });
  }

  toggleShareModal() {
    this.setState((prevState) => ({ showShareModal: !prevState.showShareModal }));
  }

  render() {
    const isLoaded = this.props.status === REDUX_STATUSES.FETCHED;
    const { eventData, registrationData, contextData } = this.props;
    const { isAdminEditingGuest, registrationStatuses, isAdminAddingGuest } = contextData;
    const {
      lookupProfile,
      showNotificationReminderModal,
      notificationEmail,
      stateRegistrationData,
      showCancelNotificationModal,
    } = this.state;
    return (
      <div>
        <AlmasightsContext.Consumer>
          {({ isLoading, almasightsInstance }) => {
            if (this.state.touchpointCreated || isLoading) {
              return;
            }

            // Create a "Viewed an event" almasights touchpoint
            almasightsInstance.trackEvent({
              touchpointType: TOUCHPOINTS.VIEWED_EVENT,
              triggerType: 'view',
              authToken: window.csrfToken,
              object: null,
            });
            this.setState({ touchpointCreated: true });
          }}
        </AlmasightsContext.Consumer>
        <DetailBar
          loading={!isLoaded}
          handleRegisterButton={(status, isAdminAddingGuestL, showEditModal) =>
            this.handleRegisterButtonAction(status, isAdminAddingGuestL, showEditModal)
          }
          buttonLoading={this.state.processing}
          handleAttendingGuestClick={() => this.toggleGuestListModal()}
          handleShareClick={() => this.toggleShareModal()}
        />
        <If condition={isLoaded}>
          <DetailBox eventData={eventData} />
        </If>
        <Choose>
          <When condition={this.state.showSignInModal}>
            <ThemeXSignInModal
              title={!isEmpty(lookupProfile) ? lookupProfile.formatted_name : 'Create an account'}
              initialStep={!isEmpty(lookupProfile) ? SIGNUP_MODAL_STEPS.LOGIN : SIGNUP_MODAL_STEPS.REGISTER}
              profileData={lookupProfile}
              visible={this.state.showSignInModal}
              handleClose={() => this.hideSignInModal()}
            />
          </When>
          <When condition={this.state.showRegistrantModal}>
            <RegistrantModal
              userData={window.guestData ? window.guestData : contextData.userData}
              isEditMode={this.state.isEditMode}
              eventData={eventData}
              eventId={eventData.id}
              isEventHasPaymentAccount={!!eventData.payment_account}
              registrant={registrationData}
              isAdminAddingGuest={isAdminAddingGuest}
              rsvpStatus={this.state.rsvpStatus}
              affiliations={contextData.affiliations}
              handleRegistrant={(registrant, isAlreadyRegistered) =>
                this.setRegistrant(registrant, isAlreadyRegistered)
              }
              handleClose={() => this.handleRegistrantCloseAction()}
            />
          </When>
          <When condition={this.state.showGuestModal}>
            <GuestCountModal
              handleGuests={(guests) => this.updateRegistrationBasedOnGuests(guests)}
              isGuestRegistrationsEnabled={eventData.is_accompanying_guest_allowed}
              isEditMode={this.state.isEditMode}
              isAdminAddingGuest={isAdminAddingGuest}
              isAdminEditingGuest={isAdminEditingGuest}
              handleCancel={() => this.handleGuestCountModalClose()}
            />
          </When>
          <When condition={this.state.showEditRegistrationActionModal}>
            <EditRegistrationActionModal
              isAdminEditingGuest={isAdminEditingGuest}
              eventData={eventData}
              isRSVPEvent={eventData.is_rsvp}
              registrationStatuses={registrationStatuses}
              registrant={registrationData}
              handleClose={() => this.hideEditRegistrationActionModal()}
              editRegistration={() => this.openNonLoginOrGuestOrLoginModalOrRegister()}
              cancelRegistration={() => this.cancelRegistration()}
              viewRegistrationDetails={() => this.redirectToGuestKit()}
              showCancelNotificationModal={showCancelNotificationModal}
              toggleCancelReminderModal={(val) => this.toggleCancelReminderModal(val)}
              makePayment={() => this.setState({ showCheckoutModal: true, showEditRegistrationActionModal: false })}
            />
          </When>
          <When condition={this.state.showCheckoutModal}>
            <CartModal isCheckout handleCancel={() => this.setState({ showCheckoutModal: false })} />
          </When>
          <When condition={this.state.showModifyRegistrationModal}>
            <ModifyRegistrationEmailModal
              eventData={eventData}
              registrant={registrationData}
              handleClose={() => this.hideModifyRegistrationModal()}
            />
          </When>
        </Choose>
        <If condition={contextData.isEventAdmin && !isAdminEditingGuest}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => this.redirectToAdmin()}>
                  <FontAwesomeIcon icon={faCog} className="mr8" />
                  <span>Admin</span>
                </Menu.Item>
                <Menu.Item onClick={() => this.handleRegisterButtonAction('yes', true)}>
                  <FontAwesomeIcon icon={faUser} className="mr8" />
                  <span>Add Guest</span>
                </Menu.Item>
                <Menu.Item onClick={() => this.handleInviteGuestAction()}>
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="mr8" />
                  <span>Invite Guests</span>
                </Menu.Item>
              </Menu>
            }
            placement="topCenter"
            trigger={['click', 'hover']}
            overlayClassName="admin-actions-dropdown">
            <Button icon={<SettingOutlined />} className="arc-floating-fixed-bottom-btn">
              Admin Actions
            </Button>
          </Dropdown>
        </If>
        <If condition={this.state.showGuestListModal}>
          <GuestListModal
            eventId={eventData.id}
            isShareEnabled={eventData.is_share_enabled}
            handleClose={() => this.toggleGuestListModal()}
          />
        </If>
        <If condition={showNotificationReminderModal}>
          <NotificationReminderModal
            ok={() => {
              this.updateRSVPStatus(stateRegistrationData, () => {
                resendConfirmationEmail({ eventId: eventData.id, registrantEmail: stateRegistrationData.email }).then(
                  () => {
                    message.success('Email sent successfully');
                  },
                );
              });
            }}
            cancel={() => {
              this.updateRSVPStatus(stateRegistrationData);
            }}
            email={notificationEmail}
          />
        </If>
        <ThemeXShareModal visible={this.state.showShareModal} handleClose={() => this.toggleShareModal()} />

        <If condition={window.isLinkExpired}>
          <Modal visible closable={false} width={478} footer={null}>
            <div className="arc-text-align-c">
              <div className="arc-H300">Invitation link already used </div>
              <div className="mt20">
                This invitation link has already been used to register for <b>{eventData.name}</b>
              </div>
              <div>
                If you would like to modify your registration, we will send an email to {window.guestData?.email} with
                details on how
              </div>
              <Button type="primary" className="mt32" onClick={this.sendEditEmail}>
                Send email
              </Button>
            </div>
          </Modal>
        </If>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  status: state.eventDataReducer.status,
  registrationData: state.registrationDataReducer.data || {},
  contextData: state.contextDataReducer || {},
});

const mapDispatchToProps = (dispatch) => ({
  setRegistrationData: (data) => dispatch(setRegistrationData(data)),
  setEventData: (data) => dispatch(setEventData(data)),
  setHeroBannerData: (data) => dispatch(setHeroBannerData(data)),
  setContextData: (data) => dispatch(setContextData(data)),
  setTicketDetailsData: (data) => dispatch(setTicketDetailsData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
