/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { shape, arrayOf, func, string, bool } from 'prop-types';
import { Row, Col } from 'antd';
import { compact, isEmpty, map, sortBy, findIndex, intersection } from 'lodash';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CRITERIA_AFFILIATION, CRITERIA_CUSTOM } from 'Src/alumniEvents/constants';
import { MANDATORY_TICKET } from '../../../constants';
import { getAgendaItems } from '../../../utils/app_moment_utils';
import { getBoughtTicketQuantity, getBoughtTicketQuantities } from '../../../utils';
import { getFormattedDate } from '../../../../common/utilities/moment_util';

import AgendaItem from '../AgendaItem';
import FundItem from '../FundItem';

import './style.scss';

const AgendaItemContainer = ({
  eventData,
  selectedTickets,
  giftDonated,
  isDonated,
  currencyEntity,
  handleTicketItemClick,
  handleAgendaItemClick,
  handleFundItemClick,
  registrationData,
}) => {
  const [agendaItems, setAgendaItems] = useState([]);
  const [tickets, setTickets] = useState([]);

  function isRegistrationPartOfAgenda(registration, agenda) {
    const registrationTicketIds = registration.tickets.map((ticket) => ticket.ticket.id);
    const agendaTicketIds = agenda.tickets.map((ticket) => ticket.id);
    return intersection(registrationTicketIds, agendaTicketIds);
  }

  function shouldShowAgenda(agenda) {
    const FORM_FIELD_TYPE_DROPDOWN = 6;
    const FORM_FIELD_TYPE_DROPDOWN_RANGE = 16;
    const FORM_FIELD_TYPE_LOCATION = 18;
    if (agenda.guests_limit && !isEmpty(eventData.count_guests_on_guest_limited_agenda)) {
      if (
        eventData.count_guests_on_guest_limited_agenda[agenda.id] >= agenda.guests_limit &&
        isEmpty(isRegistrationPartOfAgenda(registrationData, agenda))
      ) {
        return false;
      }
    }
    if (isEmpty(agenda.depends_on)) {
      return true;
    }
    if (isEmpty(agenda.depends_on.allowed_values)) {
      return true;
    }
    if (agenda.depends_on.criteria === CRITERIA_AFFILIATION) {
      if (
        isEmpty(registrationData.affiliations) ||
        isEmpty(registrationData.affiliations.filter((item) => agenda.depends_on.allowed_values.includes(item.id)))
      ) {
        return false;
      }
      return true;
    }
    if (agenda.depends_on.criteria === CRITERIA_CUSTOM) {
      // eslint-disable-next-line camelcase
      const { pre_registration_form } = eventData;
      if (!isEmpty(pre_registration_form) && !isEmpty(pre_registration_form.fields)) {
        const fieldId = pre_registration_form.fields[0].id;

        if (
          isEmpty(registrationData.pre_registration_form_entry) ||
          isEmpty(registrationData.pre_registration_form_entry.field_entries)
        ) {
          return false;
        }

        const index = findIndex(
          registrationData.pre_registration_form_entry.field_entries,
          // eslint-disable-next-line eqeqeq
          (val) => val.field_id == fieldId,
        );
        if (index === -1) {
          return false;
        }
        const formField = registrationData.pre_registration_form_entry.field_entries[index];
        if ([FORM_FIELD_TYPE_DROPDOWN_RANGE].indexOf(agenda.depends_on.field_type) !== -1) {
          if (formField.value) {
            const d = parseInt(formField.value.trim(), 10);
            return !Number.isNaN(d) && agenda.depends_on.allowed_values.indexOf(d) !== -1;
          }
          return false;
        }
        if ([FORM_FIELD_TYPE_DROPDOWN].indexOf(agenda.depends_on.field_type) !== -1) {
          return agenda.depends_on.allowed_values.indexOf(formField.value) !== -1;
        }
        if ([FORM_FIELD_TYPE_LOCATION].indexOf(agenda.depends_on.field_type) !== -1) {
          if (!isEmpty(formField.value)) {
            const d = formField.value;
            return agenda.depends_on.allowed_values.map((val) => val.formatted_address).indexOf(d) !== -1;
          }
          return false;
        }
      }
    }

    return true;
  }

  function getSelectedTicketIds() {
    return registrationData.tickets.map((val) => val.ticket.id);
  }

  function shouldShowThisTicket(ticketData, selectedTicketsL) {
    const associatedAgendas = eventData.agendas.filter(
      (val) => val.tickets.map((v) => v.id).indexOf(ticketData.id) !== -1,
    );

    // we are checking if the ticket has any agenda associated with it and if it has , see if those agendas are visible to user
    if (
      !isEmpty(associatedAgendas) &&
      isEmpty(compact(associatedAgendas.map((associatedAgenda) => shouldShowAgenda(associatedAgenda))))
    ) {
      return false;
    }

    // eslint-disable-next-line camelcase
    const { is_active, depends_on } = ticketData;
    // eslint-disable-next-line camelcase
    if (is_active) {
      // eslint-disable-next-line camelcase
      if (depends_on) {
        return selectedTicketsL.includes(depends_on);
      }
      return true;
    }
    return false;
  }

  function parseData() {
    const agendasL = [];
    const usedTickets = [];

    const selectedTicketIds = getSelectedTicketIds();
    let allTickets = [...getActiveAndNonMandatoryTickets(eventData.tickets || [])];

    eventData.agendas.forEach((agenda) => {
      if (shouldShowAgenda(agenda)) {
        const visibleTickets = agenda.tickets.filter((ticket) => shouldShowThisTicket(ticket, selectedTicketIds));
        if (!isEmpty(visibleTickets)) {
          agendasL.push({ ...agenda, visibleTickets });
          usedTickets.push(...visibleTickets.map(({ id }) => id));
        }
      }
    });
    allTickets = allTickets.filter(
      (ticket) => shouldShowThisTicket(ticket, selectedTicketIds) && !usedTickets.includes(ticket.id),
    );
    setTickets([...allTickets]);
    setAgendaItems([...agendasL]);
  }

  useEffect(() => {
    parseData();
  }, [registrationData]);

  function hasDonatedToFund(fundId) {
    const splits = (giftDonated && giftDonated.splits) || [];
    const index = splits.findIndex((item) => item.fund.id === fundId);
    return index > -1;
  }

  function getActiveAndNonMandatoryTickets(ticketsL) {
    return ticketsL.filter((ticket) => ticket.ticket_type !== MANDATORY_TICKET && ticket.is_active);
  }

  const { funds } = eventData;

  return (
    <div id="event-agenda-item-container" className="arc-card-box-shadow">
      <Row>
        {map(getAgendaItems(agendaItems), (agenda) => (
          <Col span={24} key={getFormattedDate(agenda._d, 'X')}>
            <div className="agenda-item-header">
              <p className="agenda-item-day arc-H100 arc-color-B65">{getFormattedDate(agenda._d, 'ddd')}</p>
              <p className="agenda-item-date arc-H200 arc-color-B85">{getFormattedDate(agenda._d, 'MMM D, YYYY')}</p>
            </div>
            {map(agenda.data, (item) => (
              <AgendaItem
                currencyEntity={currencyEntity}
                data={item}
                key={item.id}
                handleAgendaBookAction={() => handleAgendaItemClick(item.id)}
                boughtQuantity={getBoughtTicketQuantities(
                  selectedTickets,
                  item.tickets.map(({ id }) => id),
                )}
              />
            ))}
          </Col>
        ))}
        <If condition={tickets.length}>
          <Col span={24}>
            <div className="agenda-item-header">
              <p className="agenda-item-date arc-H200 arc-color-B85">
                {agendaItems.length ? (
                  <I18nCustomFormatter id="event-other-tickets-label" />
                ) : (
                  <I18nCustomFormatter id="event-tickets-label" />
                )}
              </p>
            </div>
            {sortBy(tickets, 'display_order', 'asc').map((ticket) => (
              <AgendaItem
                currencyEntity={currencyEntity}
                data={ticket}
                type="ticket"
                key={ticket.id}
                boughtQuantity={getBoughtTicketQuantity(selectedTickets, ticket.id)}
                handleTicketBookAction={() => handleTicketItemClick(ticket.id)}
              />
            ))}
          </Col>
        </If>
        <If condition={!isDonated && funds.length}>
          <Col span={24}>
            <div className="agenda-item-header">
              <p className="agenda-item-date arc-H200 arc-color-B85">
                <I18nCustomFormatter id="event-ask-for-gift-label" />
              </p>
            </div>
            {funds.map((item) => (
              <FundItem
                isDonated={hasDonatedToFund(item.fund.id)}
                fund={item}
                key={item.id}
                handleGiftAddAction={() => handleFundItemClick(item.id)}
              />
            ))}
          </Col>
        </If>
      </Row>
    </div>
  );
};

AgendaItemContainer.propTypes = {
  eventData: shape().isRequired,
  currencyEntity: string.isRequired,
  selectedTickets: arrayOf(),
  giftDonated: shape(),
  isDonated: bool,
  handleTicketItemClick: func.isRequired,
  handleAgendaItemClick: func.isRequired,
  handleFundItemClick: func.isRequired,
};

AgendaItemContainer.defaultProps = {
  selectedTickets: [],
  giftDonated: {},
  isDonated: false,
};

const mapStateToProps = (state) => ({
  registrationData: state.registrationDataReducer.data || {},
});

export default connect(mapStateToProps, {})(withRouter(AgendaItemContainer));
