import { INPUT_TYPES } from '../common/constants';

export const MANDATORY_TICKET = 'mandatory';
export const SEATED_TICKET = 'seated';
export const SPONSORED_TICKET = 'sponsored';
export const OPEN_TICKET = 'open';
export const GIFT_TICKET = 'gift';
export const CUSTOM_FORM_MAPPING = {
  1: INPUT_TYPES.TEXT,
  2: INPUT_TYPES.TEXTAREA,
  5: INPUT_TYPES.CHECKBOX,
  6: INPUT_TYPES.SELECT,
  8: INPUT_TYPES.RADIO,
  10: INPUT_TYPES.DATE,
};

export const REGISTRATION_TYPES = {
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  GOOGLE: 'google',
  EMAIL: 'email',
};

export const COVER_IMAGE = 'cover_image';
export const LOGO_IMAGE = 'logo_image';

export const ORIENTATION = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
};

// This mapping need to maintain because wanted to show different text on  frontend
export const RSVP_STATUSES_MAPPING = {
  maybe: 'Might attend',
  no: 'Won’t be attending',
  yes: 'Will be attending',
};

export const MAX_GUEST_ALLOWED = 100;
export const PRIVACY_PUBLIC = 'public';
export const PRIVACY_REGISTRANTS_ONLY = 'registrants_only';

// privacy settings for announcements and event venue
export const PRIVACY_SETTINGS = {
  PUBLIC: 'public',
  REGISTERED_GUESTS_ONLY: 'registered_guests_only',
};

export const REGISTRATION_STATUSES = {
  STATUS_PENDING_PAYMENT: 'pending_payment',
  STATUS_PAYMENT_INITIATED: 'payment_initiated',
  STATUS_PAYMENT_FAILED: 'payment_failed',
  STATUS_SUCCESSFUL: 'registration_successful',
  STATUS_CANCELLED: 'registration_cancelled',
};

export const RSVP_STATUSES = {
  RSVP_YES: 'yes',
  RSVP_MAYBE: 'maybe',
  RSVP_NO: 'no',
};

// Almasights touchpoints
export const TOUCHPOINTS = {
  VIEWED_EVENT: 'events-viewed-an-event',
};

// Sub Event Criterias
export const CRITERIA_NO_CRITERIA = 'no_criteria';
export const CRITERIA_AFFILIATION = 'affiliation';
export const CRITERIA_CUSTOM = 'custom';

export const SELECTABLE_FIELD_TYPES = [
  INPUT_TYPES.CHECKBOX,
  INPUT_TYPES.SELECT,
  INPUT_TYPES.SELECT_RANGE,
  INPUT_TYPES.RADIO,
];
